const { ethers } = require("ethers");

const extraList = [
  // list of extra tokens here!
  {
    address: "0x8bf4646346c16E52a8400c35045Ae7058ecd4853",
    extraAllocation: 500,
  },
  {
    address: "0xFa9b74DCe2b114F42D56B061Ba6a5C093864011C",
    extraAllocation: 500,
  },
  {
    address: "0xaf94cf32622e8034025df701ce2f3a7535e8eeed",
    extraAllocation: 500,
  },
  {
    address: "0x60565d99736a8291fd9568d0e34c04bd44f596eb",
    extraAllocation: 500,
  },
  {
    address: "0x78a9bc1eaae4f88cb94e2235ac8ce05fb345e841",
    extraAllocation: 500,
  },
  {
    address: "0xfb6380f49bd30da8ab95188086fd4f77ccaba6d4",
    extraAllocation: 500,
  },
  {
    address: "0x6683063160dfe1e7929137fb380ba1f911ca3fff",
    extraAllocation: 500,
  },
  {
    address: "0x4071da52be208af9b5db9cb5041d1107b82ac505",
    extraAllocation: 500,
  },
  {
    address: "0x17e167d89661faf7ed4f4d31901a4627a85ed9cd",
    extraAllocation: 500,
  },
  {
    address: "0x1341c8d121044a7e7f2c3bb5ea0dd9438570e70f",
    extraAllocation: 500,
  },
  {
    address: "0xc5e88651166f33274ad6b903b4d2fc55e2ecdc47",
    extraAllocation: 500,
  },
  {
    address: "0x083c828b221b126965a146658d4e512337182df1",
    extraAllocation: 500,
  },
];

export async function getBalance(address, providerRpc, wallet) {
  const ABITokens = require("./others/usABI.json");
  const provider = new ethers.JsonRpcProvider(providerRpc);
  const tokenContract = new ethers.Contract(address, ABITokens, provider);
  const result = parseInt(
    await tokenContract.pointsBalance(wallet).then((response) => response)
  );
  return result / 10 ** 18;
}

function checkIfExtra(userAddress) {
  for (let i = 0; i < extraList.length; i++) {
    if (extraList[i]["address"] == userAddress) {
      return extraList[i]["extraAllocation"];
    }
  }
  return 0;
}

export async function getPointsAirdrop(user) {
  let tokensBalance = 0;
  let added = checkIfExtra(user);
  const ABIAirdrop = [
    "function pointsBalance(address)external view returns(uint256)",
  ];
  // POLYGON
  let provider = new ethers.JsonRpcProvider("https://rpc.ankr.com/polygon");
  let tokenContract = new ethers.Contract(
    "0x072575D44F95C04d769C63d5a8D78B7e324c5021",
    ABIAirdrop,
    provider
  );
  tokensBalance += parseInt(
    await tokenContract.pointsBalance(user).then((response) => response)
  );

  // BSC
  provider = new ethers.JsonRpcProvider("https://bsc-rpc.publicnode.com");
  tokenContract = new ethers.Contract(
    "0x072575D44F95C04d769C63d5a8D78B7e324c5021",
    ABIAirdrop,
    provider
  );
  tokensBalance += parseInt(
    await tokenContract.pointsBalance(user).then((response) => response)
  );

  // BASE
  provider = new ethers.JsonRpcProvider(
    "https://base.rpc.subquery.network/public"
  );
  tokenContract = new ethers.Contract(
    "0x072575D44F95C04d769C63d5a8D78B7e324c5021",
    ABIAirdrop,
    provider
  );
  tokensBalance += parseInt(
    await tokenContract.pointsBalance(user).then((response) => response)
  );

  // OP
  provider = new ethers.JsonRpcProvider(
    "https://optimism-mainnet.public.blastapi.io"
  );
  tokenContract = new ethers.Contract(
    "0x072575D44F95C04d769C63d5a8D78B7e324c5021",
    ABIAirdrop,
    provider
  );
  tokensBalance += parseInt(
    await tokenContract.pointsBalance(user).then((response) => response)
  );

  // CELO
  provider = new ethers.JsonRpcProvider("https://rpc.ankr.com/celo");
  tokenContract = new ethers.Contract(
    "0x95f54C8a157ED563A0b5Fe410C7B5E4106a8E306",
    ABIAirdrop,
    provider
  );
  tokensBalance += parseInt(
    await tokenContract.pointsBalance(user).then((response) => response)
  );

  return tokensBalance / 1e18 + added;
}
