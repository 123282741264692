import "./style.css";
import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import bnbLogo from "../../images/chainsLogos/bnbLogo.png";
import polyLogo from "../../images/chainsLogos/polyLogo.png";
import celoLogo from "../../images/chainsLogos/celo.png";
import baseLogo from "../../images/chainsLogos/base.png";
import opLogo from "../../images/chainsLogos/optimism.png";
import noChains from "../../images/chainsLogos/nochains.png";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

const SelectChain = () => {
  const [open, setOpen] = React.useState(false);
  const totalChains = [bnbLogo, polyLogo, baseLogo, opLogo, celoLogo, noChains];
  const listChains = ["bnb", "polygon", "base", "optimism", "celo"];
  const chainIds = ["0x38", "0x89", "0x2105", "0xa", "0xa4ec"];

  const initialChain = () => {
    try {
      return window.ethereum && window.ethereum._state.isUnlocked
        ? chainIds.includes(window.ethereum.chainId)
          ? window.ethereum.chainId
          : "0x69"
        : "0x89";
    } catch {
      return "0x89";
    }
  };

  const [selectedChain, setSelectedChain] = React.useState(initialChain());
  const [chains, setChains] = React.useState(
    totalChains.slice(0, totalChains.length - 1)
  );

  const address = useSelector((state) => state.User.wallet);
  const isLogged = useSelector((state) => state.User.isLogged);
  const chainsss = useSelector((state) => state.User.chain);
  const numberLogins = useSelector((state) => state.User.login);

  const dispatch = useDispatch();
  const changeWallet = React.useCallback(async ({ newChain }) => {
    dispatch({
      type: "changeWallet",
      CurrentWallet: address,
      isLogged: true,
      chain: newChain,
      login: numberLogins + 1,
    });
  });

  const getImageFromId = (chainId) => {
    switch (chainId) {
      case "0x38":
        return bnbLogo;
      case "0x89":
        return polyLogo;
      case "0x2105":
        return baseLogo;
      case "0xa":
        return opLogo;
      case "0xa4ec":
        return celoLogo;
      default:
        return noChains;
    }
  };

  const handleChange = (event) => {
    let value = event.target.value;
    const newChains = [];
    for (let i = 0; i < listChains.length; i++) {
      if (listChains[i].startsWith(value)) newChains.push(totalChains[i]);
    }
    setChains(newChains);
  };

  const switchToNetwork = async (networkName) => {
    if (window.ethereum) {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: networkName }],
      });
    }
  };

  async function wrapperStuf() {
    await doStuff();
  }

  const doStuff = async () => {
    if (
      !chainIds.includes(window.ethereum.chainId) &&
      isLogged &&
      selectedChain != "0x69"
    ) {
      setSelectedChain("0x69");
      handleOpen();
      await changeWallet({ newChain: "0x69" });
    } else {
      setSelectedChain(window.ethereum.chainId);
      await changeWallet({ newChain: window.ethereum.chainId });
    }
  };

  React.useEffect(() => {
    if (selectedChain == "0x69" && isLogged)
      toast.error("Unsupported network!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
  }, [selectedChain]);

  try {
    if (window.ethereum && !open) {
      window.ethereum.on("chainChanged", wrapperStuf);
    }
  } catch {}

  async function handleChain(index) {
    const id = toast.loading("changing network...", {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    try {
      await switchToNetwork(chainIds[index]);
      setSelectedChain(chainIds[index]);
      await changeWallet({ newChain: chainIds[index] });
      toast.update(id, {
        render: "Chain changed!",
        type: "success",
        isLoading: false,
        autoClose: 1000,
      });
      handleClose();
    } catch (e) {
      toast.update(id, {
        render: "Error!",
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
    }
  }

  React.useEffect(() => {
    try {
      if (
        (!chainIds.includes(window.ethereum.chainId) ||
          !chainIds.includes(chainsss)) &&
        isLogged
      ) {
        handleOpen();
        setSelectedChain("0x69");
        changeWallet({ newChain: "0x69" });
      } else {
        if (isLogged) setSelectedChain(chainsss);
      }
    } catch {}
  }, [address]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const listItems = chains.map((element, index) => {
    return (
      <td key={index} className="listChains" onClick={() => handleChain(index)}>
        <img src={element} className="chain" />
      </td>
    );
  });

  return (
    <div>
      <a className="chainSelect" onClick={handleOpen}>
        <img src={getImageFromId(selectedChain)} className="chainImage" />
      </a>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="boxChainSelect">
          <input
            className="inputChain"
            placeholder="Search Chain"
            autocomplete="off"
            aria-invalid="false"
            onChange={handleChange}
          />
          <div className="mainChainList">{listItems}</div>
        </Box>
      </Modal>
    </div>
  );
};

export default SelectChain;
